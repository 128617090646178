<template>
  <div 
    class="notification"
    :class="type" 
  >
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    type: String
  },
};
</script>

<style lang="scss" scoped>
.notification {
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
    padding: 18px;
    width: 80%;
    font-size: 23px;
    font-weight: bold;
}
.reqest {
    background-color: #4188A9;
    color: #fff;
}
.success {
    background-color: #8BEF88;
    color: #002036;
}
.partial_success {
    background-color: #EEDD00;
    color: #002036;
}
.partial_declind {
    background-color: #FF0004;
    color: #002036;
}
.localcancel_success {
    background-color: #E05D00;
    color: #fff;
}
</style>