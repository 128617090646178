<template>
  <main class="color-primary h-100 bg-yellow3 blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <Notification type="reqest">
      <template v-slot:content>
        <span>ביטול נדחה - כל הסכום נוצל</span>
      </template>  
    </Notification> 
    <p class="main-title weight-700">
      {{ $t('Product promotions') }}
    </p>
    <section class="filter-part content-margin d-flex">
      <div class="form-item">
        <label>{{ $t("All resellers") }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input type="text" class="input" :placeholder="$t('All resellers')" />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('Select a supplier') }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input type="text" class="input" :placeholder="$t('Select a supplier')" />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('Choose a product') }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input type="text" class="input" :placeholder="$t('Choose a product')" />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('Status') }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input type="text" class="input" :placeholder="$t('Status')" />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t("Charge date") }}</label>
        <div class="d-flex align-items-center p-relative mt-2 mb-7">
          <Datepicker
            format="d MMMM yyyy"
            class="input cus-datepicker"
            :language="languages[currentLang]"
          ></Datepicker>
          <img
            src="/assets/img/icons/calendar-alt-light.svg"
            alt=""
            class="calendar-img"
          />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t("Cancellation date") }}</label>
        <div class="d-flex align-items-center p-relative mt-2 mb-7">
          <Datepicker
            format="d MMMM yyyy"
            class="input cus-datepicker"
            :language="languages[currentLang]"
          ></Datepicker>
          <img
            src="/assets/img/icons/calendar-alt-light.svg"
            alt=""
            class="calendar-img"
          />
        </div>
      </div>
      <div class="primary-btn filter-btn">
        {{ $t('Search') }}
      </div>
    </section>
    <section class="filter-part content-margin">
      <div class="d-flex align-items-center">
        <v-checkbox
          color="#0D3856"
          class="cus-checkbox"
        ></v-checkbox>
        <label for="" class="px-2">{{ $t("Show cancellations in the system only") }}</label>
      </div>
      <div class="d-flex align-items-center">
        <v-checkbox
          color="#0D3856"
          class="cus-checkbox"
        ></v-checkbox>
        <label for="" class="px-2">{{ $t("Show open cancellations only") }}</label>
      </div>
    </section>
    <section class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('Search') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="vat_list"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
        show-select
        :single-expand="false"
        :expanded.sync="expanded"
      >
        <template v-slot:[`item.expand`]="{ item }">
          <v-btn small @click="expanded = [item]" v-if="!expanded.includes(item)">{{ $t('More') }}</v-btn>
          <v-btn small @click="expanded = []" v-if="expanded.includes(item)">{{ $t('Less') }}</v-btn>
        </template>
        <template v-slot:[`item.submitted`]="{ }">
          <div class="ticket-btn submitted-ticket">
            הגש בקשה
          </div>
        </template>
        <template v-slot:[`item.void`]="{ }">
          <div class="ticket-btn void-ticket">
            בוטל
          </div>
        </template>
        <template v-slot:[`item.rejected`]="{ }">
          <div class="ticket-btn rejected-ticket">
            ביטול נדחה
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
                More info about {{ item.amountCanceled }}
            </td>
        </template>
      </v-data-table>
    </section>
    <section class="botton-action">
      <button class="primary-btn bg-yellow" @click="showDetailModal">
        {{ $t('Save') }}
      </button>
    </section>
    <modal
      :width="1200"
      :adaptive="true"
      class="detailModal CEModal modal-scroll-bar"
      name="detailModal"
    >
      <!-- <button class="button close-btn" @click="closeDetailModal()">
        <img src="/assets/img/close.svg" alt />
      </button> -->
      <h4 class="text-center mb-7">{{ $t('Cancellation details') }}</h4>
      <div class="detail-table">
        <div class="text-center td-item">
          <p><b>{{ $t("Deal number") }}</b></p>
          <p>54684951</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Business name') }}</b></p>
          <p>המכולת של מוטי</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Business name') }}</b></p>
          <p>המכולת של מוטי</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Customer number') }}</b></p>
          <p>10</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Agent') }}</b></p>
          <p>גבי מזוז</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Supplier ID') }}</b></p>
          <p>סלקום</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Product') }}</b></p>
          <p>טוקמן 59 שח ללא הגבלה</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Price') }}</b></p>
          <p>59</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Phone') }}</b></p>
          <p>0526192666</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t("Date and time of loading") }}</b></p>
          <p>23/06/2020 15:57:01</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t("Date and time of the request") }}</b></p>
          <p>25/06/2020 18:36:01</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Status') }}</b></p>
          <p class="color-red6">ביטול נדחה</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t("Canceled amount") }}</b></p>
          <p class="color-red6">0</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('How to request cancellation') }}</b></p>
          <p>במערכת/מייל/ טלפוני</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Date and time of cancellation') }}</b></p>
          <p>25/06/2020 18:36:01</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Date and time of cancellation') }}</b></p>
          <p>25/06/2020 18:36:01</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Cancellation operation') }}</b></p>
          <p>נוי אלמליח</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Supplier transaction number') }}</b></p>
          <p>5614616</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Supplier confirmation number') }}</b></p>
          <p>1564564</p>
        </div> 
        <div class="text-center td-item">
          <p><b>{{ $t('Remarks') }}</b></p>
          <p>מהנדה דגכדגיכןו ןכייגכ דגיכדגכדגכ ןדגוכןםדגכםדמ דכודםכו</p>
        </div> 
      </div>
      <div class="d-flex mt-10">
        <div class="d-flex align-items-center">
          <v-checkbox
            color="#0D3856"
            class="cus-checkbox"
            v-model="isCancellationSystem"
          ></v-checkbox>
          <label class="px-5 color-primary cancel-label">{{
            $t("Cancellation in the system")
          }}</label>
        </div>
        <div class="d-flex align-items-center mx-10" v-if="isCancellationSystem">
          <label class="px-5 color-primary cancel-label space-nowrap">{{
            $t("Cancellation in the system")
          }}</label>
          <input type="text" class="input">
        </div>
      </div>
      <p class="color-red6 mt-5" v-if="isCancellationSystem">{{ $t('Pay attention!! This cancellation will only be made on Platok and not with the supplier *') }}</p>
      <div class="d-flex justify-content-center mt-15 my-20">
        <button class="primary-btn bg-red1 mx-5">
          {{ $t('Which cancellation') }}
        </button>
        <button class="primary-btn mx-5" @click="closeDetailModal()">
          {{ $t("Closed") }}
        </button>
      </div>
    </modal>
  </main>
</template>

<script>
import Notification from '../../../components/Notification';

export default {
  name: 'CancellationSystem',
  components: {
    Notification
  },
  data() {
    return {
      search: '',
      expanded: [],
      headers: [
        {
          text: this.$t("Deal number"),
          align: 'center',
          sortable: false,
          value: 'deal_number',
        },
        {
          text: this.$t('Business name'),
          align: 'center',
          sortable: false,
          value: 'business_name',
        },
        {
          text: this.$t('Customer number'),
          align: 'center',
          sortable: false,
          value: 'customer_number',
        },
        {
          text: this.$t('Agent'),
          align: 'center',
          sortable: false,
          value: 'agent',
        },
        {
          text: this.$t('Supplier ID'),
          align: 'center',
          sortable: false,
          value: 'supplier',
        },
        {
          text: this.$t('Product'),
          align: 'center',
          sortable: false,
          value: 'product',
        },
        {
          text: this.$t('Price'),
          align: 'center',
          sortable: false,
          value: 'price',
        },
        {
          text: this.$t('Phone'),
          align: 'center',
          sortable: false,
          value: 'phone',
        },
        {
          text: this.$t("Date and time of loading"),
          align: 'center',
          sortable: false,
          value: 'loadingTime',
        },
        {
          text: this.$t("Date and time of the request"),
          align: 'center',
          sortable: false,
          value: 'applicationTime',
        },
        {
          text: this.$t('Status'),
          align: 'center',
          sortable: false,
          value: 'status',
        },
        {
          text: this.$t("Canceled amount"),
          align: 'center',
          sortable: false,
          value: 'amountCanceled',
        },
        {
          text: "",
          align: 'center',
          sortable: false,
          value: 'submitted',
        },
        {
          text: "",
          align: 'center',
          sortable: false,
          value: 'void',
        },
        {
          text: "",
          align: 'center',
          sortable: false,
          value: 'rejected',
        },
        {
          text: "",
          align: 'center',
          sortable: false,
          value: 'expand',
        }
      ],
      vat_list: [
        {
          deal_number: '54684951',
          business_name: 'המכולת של מוטי',
          customer_number: '10',
          agent: 'גבי מזוז',
          supplier: 'סלקום',
          product: `טוקמן 59 שח ללא הגבלה`,
          price: '59',
          phone: '0526192666',
          loadingTime: `23/06/2020 15:57:01`,
          applicationTime: '25/06/2020 18:36:01',
          status: 'בקשה הוגשה',
          amountCanceled: '59',
          submitted: '1',
          void: '1',
          rejected: '1',
        },
      ],
      isCancellationSystem: false
    };
  },
  methods: {
    showDetailModal() {
      this.$modal.show("detailModal");
      $("html").css("overflow", "hidden");
    },
    closeDetailModal() {
      this.$modal.hide("detailModal");
      $("html").css("overflow", "auto");
    },
  },
  mounted() {
    document.getElementsByTagName("html")[0].style.background = "#fbffd8";
  },
};
</script>
<style lang="scss">
.detailModal {
  .vm--modal {
    padding: 20px 30px;
    font-size: 16px;
  }
}
</style>
<style lang="scss" scoped>
label {
  font-size: 18px;
  display: block;
  height: 22px;
}
.filter-part {
  .form-item {
    margin-left: 20px;
    max-width: 240px;
  }
  .calendar-img {
    width: 26px;
    position: absolute;
    top: 8px;
    left: 7px;
  }
  .filter-btn {
    width: auto;
    padding: 0 20px;
    border-radius: 5px;
    margin-top: 27px;
  }
}
.ticket-btn {
  width: 60px;
  height: 40px;
  border: 1px solid #CBCBCB;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.3em;
}
.submitted-ticket {
  background-color:#EEDD00;
}
.void-ticket {
  background-color:#3EB23A;
  opacity: 0.6;
}
.rejected-ticket {
  background-color:#FF0000;
  color: #fff;
  opacity: 0.4;
}

.botton-action {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 35px;
  .primary-btn {
    margin: 0 15px;
    width: 190px;
    height: 40px;
    font-size: 16px;
  }
}
.detailModal {
  color: #707070;
  // h4 {
  //   text-align: center;
  // }
  .detail-table {
    background-color: #F4FFE9;
    display: flex;
    flex-wrap: wrap;
    .td-item {
      padding: 10px 20px;
    }
  }
  .input {
    height: 36px;
  }
  .primary-btn {
    width: auto;
    padding: 0 20px;
  }
}
@media screen and (max-width: 1200px) {
  .filter-part {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .filter-part {
    margin: 20px;
  }
  .table-content-part {
    margin: 20px 20px 0;
  }
}
@media screen and (max-width: 559px) {
  .filter-part {
    .form-item {
      max-width: 100%;
      width: 100%;
      margin-left: 0;
    }
    .filter-btn {
      width: 100%;
    }
  }
}
</style>